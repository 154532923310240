<script setup lang="ts">
import { computed } from 'vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import type { Activity } from '/~/composables/activity'
import {
  ReturnReasonsMap,
  ReturnReasonCompletedWithRemittanceFailures,
  ReturnReasonCompletedWithRemittanceFailuresSummary,
} from '/~/composables/activity/activity-enums'

interface Props {
  status: string
  subStatus?: string
  activity?: Activity
}

const props = withDefaults(defineProps<Props>(), {})

const statusMessageKey = computed(() => {
  if (props.status === 'cancelled') {
    if (
      props.subStatus &&
      ['payee_deleted', 'payee_blacklisted', 'payee_rejected'].includes(
        props.subStatus
      )
    ) {
      return 'notAvailable'
    }
    return 'cancelledManually'
  }
  if (props.status === 'expired') {
    return 'expired'
  }
  if (['payment_failed', 'failed'].includes(props.status)) {
    return 'failed'
  }
  if (props.status === 'completed_with_remittance_failures') {
    return 'partiallyCompleted'
  }
  return null
})

const messageContent = {
  notAvailable:
    'This PayID payment was cancelled because one or more payees are no longer available.',
  expired:
    'This PayID payment was cancelled because the PayID details expired.',
  cancelledManually: 'This PayID payment was cancelled manually.',
  failed: ReturnReasonsMap['payment_failed'],
  partiallyCompleted: {
    main: ReturnReasonCompletedWithRemittanceFailures,
    summary: ReturnReasonCompletedWithRemittanceFailuresSummary,
  },
}
</script>

<template>
  <div>
    <div v-if="statusMessageKey">
      <template v-if="statusMessageKey === 'partiallyCompleted'">
        <div class="space-y-6">
          <div>{{ messageContent.partiallyCompleted.main }}</div>
          <div>{{ messageContent.partiallyCompleted.summary }}</div>
        </div>
      </template>
      <template v-else-if="statusMessageKey === 'failed'">
        <div class="space-y-6">
          <div>{{ messageContent.failed }}</div>
        </div>
      </template>
      <template v-else>
        <div>{{ messageContent[statusMessageKey] }}</div>
      </template>
    </div>

    <template v-if="activity?.refundFailedActivity">
      <div class="mt-6">
        You can view the details of the return transaction below.
      </div>
      <base-link
        :to="{
          name: 'activity-details',
          params: { id: activity.refundFailedActivity.id },
        }"
        class="mt-6 inline-block border-b"
      >
        View details
      </base-link>
    </template>
  </div>
</template>
