<script setup lang="ts">
import { computed } from 'vue'
import { OrderResponse, OrderFeesItem } from '/~/types/api'
import ui from '/~/core/ui'
import { formatDate, createDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { pluralize } from '/~/utils/format/string'
import PaymentStatusTracker from '/~/components/activity/details/payment-status-tracker/payment-status-tracker.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { StatusTracker, type Activity } from '/~/composables/activity'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLocalization } from '/~/composables/localization'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useUser } from '/~/composables/user'
import PaymentPayWithItem from '/~/templates/bill-payments-v1_5/views/payments/make/views/successful/components/payment-pay-with-item.vue'

export type PaymentTransactionDetailsProps = {
  flowType: FlowType
  order: OrderResponse // TODO: add typeof ActivityStatus.prototype
  activity?: Activity
  viewButtonEnabled?: boolean
  viewButtonTitle?: string
  doneButtonEnabled?: boolean
  doneButtonTitle?: string
  printButtonEnabled?: boolean
  printButtonTitle?: string
}

const props = withDefaults(defineProps<PaymentTransactionDetailsProps>(), {
  viewButtonEnabled: true,
  viewButtonTitle: 'Activity',
  doneButtonEnabled: true,
  doneButtonTitle: 'Done',
  printButtonEnabled: true,
  printButtonTitle: 'View Tax Invoice',
})

const emit = defineEmits<{
  (event: 'view'): void
  (event: 'done'): void
  (event: 'print'): void
  (event: 'back'): void
}>()

const { user } = useUser()

const payee = computed(() =>
  'payee' in props.order ? props.order.payee : undefined
)
const parsedBsb = computed(() => {
  const { parseBsb } = useLocalization()

  return parseBsb(payee.value?.bsb, { showLabel: true })
})
const metadata = computed(
  () => 'metadata' in props.order && props.order.metadata
)
const statusTracker = computed(() => {
  if ('statusTracker' in props.order && !isScheduledAtValid.value) {
    return props.order.statusTracker.map((item) => new StatusTracker(item))
  }

  return []
})

const hasProcessingFee = computed(() =>
  props.order.fees.items.find(
    (item) => parseFloat(item.amount) > 0 && item.label === 'Processing Fee'
  )
)

const payeeCount = computed(() =>
  'transactionsStatusCount' in props.order
    ? props.order.transactionsStatusCount.reduce((acc, item) => {
        return acc + item.count
      }, 0)
    : 0
)

const label = computed(() =>
  props.order?.status?.toLowerCase() === 'scheduled'
    ? 'Payment scheduled'
    : 'Payment receipt'
)

const sortedPaymentMethods = computed(() =>
  props.order.paymentMethods.toSorted((a) =>
    a.type === PaymentMethodType.points ? -1 : 1
  )
)

function getFeeLabel(fee: OrderFeesItem) {
  const percentage = parseFloat(fee.percentageFee)

  let label = `${fee.label.toLowerCase()}`

  if (fee.label === 'Program Fee') {
    label += ' '

    if (percentage > 0) {
      label += `(${percentage}% inc. GST)`
    } else {
      label += '(inc. GST)'
    }
  } else if (fee.label === 'Processing Fee') {
    label += '* '

    if (percentage > 0) {
      label += `(${percentage}%)`
    } else {
      const paymentMethods = props.order.paymentMethods

      if (
        paymentMethods.length === 1 &&
        paymentMethods[0].feeMsfPercentageRate > 0
      ) {
        label += `(${paymentMethods[0].feeMsfPercentageRate}%)`
      }
    }
  }

  return label
}

const referenceBankAccount = computed(() =>
  'payee' in props.order &&
  props.order.payee.type === 'bankAccount' &&
  props.order.reference
    ? props.order.reference
    : null
)

const referenceBPay = computed(() =>
  'payee' in props.order &&
  props.order.payee.type === 'bpay' &&
  props.order.payee.reference
    ? props.order.payee.reference
    : null
)

const isScheduledAtValid = computed(
  () => props.order.scheduledAt && props.order.isStatusScheduled
)

const showFees = computed(() => {
  return props.order.fees && props.order.fees.total > 0
})

const tier = computed(() =>
  (
    props.order.metadata?.membershipName || user.value.membershipName
  )?.toLowerCase()
)
</script>

<template>
  <div class="flex flex-col">
    <div v-if="activity" class="hidden items-center justify-between sm:flex">
      <div class="my-8">
        <base-button look="link" @click="emit('back')">
          <base-icon svg="chevron-left" size="xl" class="-ml-1.5" />
          <span class="text-base">Back</span>
        </base-button>
      </div>

      <base-button v-if="printButtonEnabled" size="sm" @click="emit('print')">
        {{ printButtonTitle }}
      </base-button>
    </div>
    <div class="flex flex-grow flex-col bg-white pt-8 sm:rounded-lg sm:border">
      <div>
        <div class="mb-3 text-center text-2xl font-bold">
          <template v-if="isScheduledAtValid">
            Scheduled for:
            {{ formatDate('daymonthyearnumeric', order.scheduledAt) }}
          </template>
          <template v-else>
            <div class="flex flex-col items-center gap-y-5">
              <base-icon
                v-if="order.statusLabel?.icon"
                :svg="order.statusLabel?.icon"
                :class="[
                  order.statusLabel?.iconClass || order.statusLabel?.color,
                ]"
                :size="56"
              />
              <div v-if="ui.desktop">
                {{ order.statusLabel?.heading }}
              </div>
            </div>
          </template>
        </div>
        <div class="text-center text-sm font-bold text-eonx-neutral-800">
          Transaction ID #{{ order.number }}
        </div>
        <div
          v-if="tier"
          class="mt-1 text-center text-sm font-bold text-eonx-neutral-800"
        >
          Tier:
          <span class="inline-block first-letter:uppercase">
            {{ tier }}
          </span>
        </div>
        <div
          v-if="order.statusLabel?.text"
          class="mt-1 text-center text-sm font-bold text-eonx-neutral-800"
        >
          Status:
          <span
            class="inline-block first-letter:uppercase"
            :class="[order.statusLabel?.color]"
          >
            {{ order.statusLabel?.text }}
          </span>
        </div>
        <div
          v-if="activity?.metadata.performedBy"
          class="mt-1 text-center text-sm font-bold text-eonx-neutral-800"
        >
          Performed by: {{ activity?.metadata.performedBy?.fullName }}
        </div>
        <slot name="payid-status-description" />
        <payment-status-tracker
          v-if="statusTracker.length"
          class="mb-8 mt-6"
          :items="statusTracker"
        />
        <slot name="points-panel">
          <div class="border-b" />
        </slot>
      </div>

      <div class="h-full px-6 pb-6">
        <slot name="pay-with-payid" />
        <div class="flex flex-col gap-2 border-b py-5 font-normal">
          <div class="font-bold text-eonx-neutral-800">Paying to</div>
          <div
            v-if="'fileName' in order"
            class="flex flex-col gap-2 text-eonx-neutral-600"
          >
            <div class="flex flex-wrap">
              <div class="truncate pr-1">{{ order.fileName }}</div>
              <div>
                ({{ payeeCount }} {{ pluralize(payeeCount, 'payment') }})
              </div>
            </div>
            <div v-if="order.reference">Description: {{ order.reference }}</div>
          </div>

          <div v-if="payee" class="flex flex-col gap-2 text-eonx-neutral-600">
            <div v-if="payee.name">
              {{ payee.name }}
            </div>
            <div v-for="(item, key) in parsedBsb" :key="key">{{ item }}</div>
            <div v-if="payee.accountNumber">
              Account No: {{ payee.accountNumber }}
            </div>
            <div v-if="payee.billerCode">
              Biller Code: {{ payee.billerCode }}
            </div>
            <div v-if="referenceBPay">Reference: {{ referenceBPay }}</div>

            <template v-if="'userStatementAccount' in order">
              <div>Account Name: {{ order.userStatementAccount.name }}</div>
              <div>Account No: {{ order.userStatementAccount.number }}</div>
              <div>
                Description: {{ payee.name }}
                {{ createDate(order.processingDueDate).format('MMMM') }}
              </div>
            </template>
          </div>
          <div
            v-if="metadata && metadata.payloadStatementDescriptor"
            class="text-eonx-neutral-600"
          >
            Description: {{ metadata.payloadStatementDescriptor }}
          </div>
          <div v-if="referenceBankAccount" class="text-eonx-neutral-600">
            Reference: {{ referenceBankAccount }}
          </div>
          <div class="flex justify-between font-bold">
            <div>Amount</div>
            <div>{{ formatDollar(order.subtotal) }}</div>
          </div>
        </div>

        <div
          v-if="showFees"
          class="flex flex-col gap-2 border-b py-5 font-normal"
        >
          <div class="font-bold text-eonx-neutral-800">Fees</div>
          <div
            v-for="fee in order.fees.items"
            :key="fee.label"
            class="flex justify-between"
          >
            <div class="first-letter:uppercase">
              {{ getFeeLabel(fee) }}
            </div>
            <div class="text-sm">{{ formatDollar(fee.amount) }}</div>
          </div>
          <div class="flex items-center justify-between font-bold">
            <div>Total fees</div>
            <div>{{ formatDollar(order.fees.total) }}</div>
          </div>
          <div v-if="hasProcessingFee" class="text-sm text-eonx-neutral-600">
            *Processing fee is charged on amount plus program fee.
          </div>
        </div>

        <div
          class="flex items-center justify-between border-b py-5 font-bold text-eonx-neutral-800"
        >
          <div>Total</div>
          <div class="text-lg">{{ formatDollar(order.total) }}</div>
        </div>

        <div class="flex flex-col gap-2 pt-5 font-normal">
          <div class="font-bold text-eonx-neutral-800">Paying from</div>
          <payment-pay-with-item
            v-for="paymentMethod in sortedPaymentMethods"
            :key="paymentMethod.id"
            :payment-method="paymentMethod"
          />
        </div>
      </div>

      <div
        v-if="viewButtonEnabled || doneButtonEnabled"
        class="border-t px-5 py-5"
      >
        <div
          class="grid grid-cols-1 gap-2"
          :class="{
            'xs:grid-cols-2': viewButtonEnabled && doneButtonEnabled,
          }"
        >
          <base-button
            v-if="viewButtonEnabled"
            v-analytics:click="{
              pageGroup: 'Make a payment',
              page: label,
              cta: viewButtonTitle,
            }"
            data-vitest="view-button"
            look="outlined-color"
            @click="emit('view')"
          >
            {{ viewButtonTitle }}
          </base-button>

          <base-button
            v-if="doneButtonEnabled"
            v-analytics:click="{
              pageGroup: 'Make a payment',
              page: label,
              cta: doneButtonTitle,
            }"
            @click="emit('done')"
          >
            {{ doneButtonTitle }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
